import { Card, Col, Row } from "antd";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import { FC } from "react";
import { Athlete } from "./AthleteOverView";
import { Link } from "react-router-dom";

type AthleteCardProps = {
  athlete: Athlete;
};

const AthleteCard: FC<AthleteCardProps> = ({ athlete }) => {
  return (
    <div className="content-card">
      <Card
        title={`${athlete.firstName} ${athlete.lastName}`}
        extra={
          <>
            <Link to={`/atleten/${athlete.id}`}>Detail</Link>
            <CheckCircleOutlined />
            <StopOutlined />
          </>
        }
        hoverable
        size="small"
      >
        <Row justify="space-between">
          <Col>2de jaar scholier</Col>
          <Col>{athlete.dateOfBirth}</Col>
        </Row>
      </Card>
    </div>
  );
};

export default AthleteCard;
