import { useAuth0 } from "@auth0/auth0-react";
import { Col } from "antd";
import PublicLandingPage from "./PublicLandingPage";
import AuthenticatedContent from "./AuthenticatedContent";
import { FC } from "react";

const App: FC = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <Col span={24} className="appbody">
      {isAuthenticated ? <AuthenticatedContent /> : <PublicLandingPage />}
    </Col>
  );
};

export default App;
