import React, { FC } from "react";
// import { useParams } from "react-router-dom";
// import { AthleteDetailPageRouteParameters } from "./AuthenticatedContent";
import AthleteDetail from "./AthleteDetail";

const AthleteDetailPage: FC = () => {
  // const { athleteId } = useParams<AthleteDetailPageRouteParameters>();
  // TODO: fetch athleteDetail
  return (
    <AthleteDetail
      athlete={{
        activityStatus: "active",
        creationTime: "2022-09-09",
        dateOfBirth: "2022-09-09",
        firstName: "Lorem",
        id: "identifier",
        lastName: "Ipsum",
        lastSeen: "2022-09-09",
        updateTime: "2022-09-09",
      }}
    />
  );
};

export default AthleteDetailPage;
