import { withAuthenticationRequired } from "@auth0/auth0-react";
import { FC } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AthleteDetailPage from "./AthleteDetailPage";
import AthleteOverview from "./AthleteOverView";
import Header from "./Header";
import NewAthleteForm from "./NewAthleteForm";
import NewSessionForm from "./NewSessionForm";
import SessionOverview from "./SessionOverview";
import UserProfile from "./UserProfile";

export type AthleteDetailPageRouteParameters = {
  athleteId: string;
};

const AuthenticatedContent: FC = () => (
  <Router>
    <Header />
    <Switch>
      <Route path="/atleten/nieuw">
        <NewAthleteForm />
      </Route>
      <Route
        path="/atleten/:athleteId"
        children={<AthleteDetailPage />}
      ></Route>
      <Route path="/atleten">
        <AthleteOverview />
      </Route>
      <Route path="/trainingen/nieuw">
        <NewSessionForm />
      </Route>
      <Route path="/trainingen">
        <SessionOverview />
      </Route>
      <Route path="/profiel">
        <UserProfile />
      </Route>
      <Route path="/">
        <div>Default landing page</div>
      </Route>
    </Switch>
  </Router>
);

export default withAuthenticationRequired(AuthenticatedContent, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});
