import { FC, useState } from "react";
import { Button, Col, message, Row, Steps } from "antd";

const { Step } = Steps;

const steps = [
  {
    title: "Training details",
    content: "First-content",
  },
  {
    title: "Aanwezige atleten",
    content: "Second-content",
  },
  {
    title: "Verantwoordelijke trainer",
    content: "Last-content",
  },
];

const NewSessionForm: FC = () => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <Col>
      <Row justify="center">
        <Col span={16}>
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default NewSessionForm;
