import { Card, Col } from "antd";
import { FC, useMemo } from "react";
import { Session } from "./SessionOverview";

type SessionCardProps = {
  session: Session;
};

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const SessionCard: FC<SessionCardProps> = ({ session }) => {
  const day = useMemo(() => DAYS[new Date(session.date).getDay()], [session]);

  return (
    <div className="content-card">
      <Card
        title={`${day} ${session.date}`}
        extra={<a href="/">Detail</a>}
        size="small"
      >
        <Col>{session.athleteIds.length} atleten</Col>
      </Card>
    </div>
  );
};

export default SessionCard;
