import { Col, Row } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";

const Header: FC = () => {
  return (
    <Row align="middle" className="header">
      <Col span={24}>
        <Row justify="center">
          <Col span={12}>
            <Row justify="space-between">
              <Col>Saturnal Attendances</Col>
              <Col span={6}>
                <Row justify="space-between">
                  <Col>
                    <Link to="/atleten">Atleten</Link>
                  </Col>
                  <Col>/</Col>
                  <Col>
                    <Link to="/trainingen">Trainingen</Link>
                  </Col>
                  <Col>/</Col>
                  <Col>
                    <Link to="/profiel">Profiel</Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
