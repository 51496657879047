import { useAuth0 } from "@auth0/auth0-react";
import { Col, DatePicker, Row } from "antd";
import { FC, useCallback, useState } from "react";
import { Field, Form } from "react-final-form";
import AthleteDetail from "./AthleteDetail";
import { Athlete, parseAthlete } from "./AthleteOverView";

const NewAthleteForm: FC = () => {
  const { getIdTokenClaims } = useAuth0();
  const [createdAthlete, setCreatedAthlete] = useState<Athlete>();
  const onSubmit = useCallback(
    async (values) => {
      const newAthleteData = {
        firstName: { S: values.firstName },
        lastName: { S: values.lastName },
        dateOfBirth: { S: values.dateOfBirth.format("yyyy-MM-DD") },
        lastSeen: { S: values.dateOfBirth.format("yyyy-MM-DD") },
        activityStatus: { S: "active" },
      };
      try {
        // TODO: NOT RIGHT WAY TO ACCESS APIs!!
        const idtoken = await getIdTokenClaims();
        const token = idtoken.__raw;
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newAthleteData),
        };
        // for aborting requests before finish:
        // https://dev.to/pallymore/clean-up-async-requests-in-useeffect-hooks-90h
        const response = await fetch(
          "https://orrnfk6l4g.execute-api.eu-west-3.amazonaws.com/prod/athlete",
          requestOptions
        );
        if (response.status !== 201) {
          console.error("error");
          console.error(await response.json());
        }
        const responseJson = await response.json();
        const athlete = parseAthlete(responseJson);
        setCreatedAthlete(athlete);
      } catch (e) {
        console.error(e);
      }
    },
    [getIdTokenClaims]
  );

  const validate = useCallback((values) => {
    console.log("validate");
    return undefined;
  }, []);

  const required = useCallback(
    (value: any) => (value ? undefined : "Required"),
    []
  );

  const DatePickerAdapter = useCallback(
    ({ input: { onChange, value }, ...rest }) => (
      <DatePicker
        selected={value}
        onChange={(date) => onChange(date)}
        {...rest}
      />
    ),
    []
  );

  return (
    <Row style={{ textAlign: "center" }}>
      <Col span={12}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <h2>Nieuwe atleet toevoegen</h2>
              <div>
                <label>Voornaam: </label>
                <Field
                  name="firstName"
                  component="input"
                  placeholder="Thomas"
                  validate={required}
                />
              </div>
              <div>
                <label>Familienaam: </label>
                <Field
                  name="lastName"
                  component="input"
                  placeholder="Janssen"
                  validate={required}
                />
              </div>
              <div>
                <label>Geboortedatum: </label>
                <Field
                  name="dateOfBirth"
                  validate={required}
                  dateFormat="dd/MM/yyyy"
                  component={DatePickerAdapter}
                />
              </div>

              <button type="submit">Voltooi</button>
            </form>
          )}
        />
      </Col>
      <Col span={12}>
        {createdAthlete && (
          <>
            <Row>Created Athlete: </Row>
            <Row>
              <AthleteDetail athlete={createdAthlete} />
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default NewAthleteForm;
