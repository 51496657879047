import { useAuth0 } from "@auth0/auth0-react";
import { Col, Row } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import SessionCard from "./SessionCard";

type AWSSession = {
  athleteIds: { L: { S: string }[] };
  coachIds: { L: { S: string }[] };
  creationTime: { S: string };
  date: { S: string };
  duration: { S: string };
  season: { S: string };
  startTime: { S: string };
  updateTime: { S: string };
};
export type Session = {
  athleteIds: string[];
  coachIds: string[];
  creationTime: string;
  date: string;
  duration: string;
  season: string;
  startTime: string;
  updateTime: string;
};

const parseSession = (session: AWSSession) => {
  return {
    athleteIds: session.athleteIds.L.map((AWSId) => AWSId.S),
    coachIds: session.coachIds.L.map((AWSId) => AWSId.S),
    creationTime: session.creationTime.S,
    date: session.date.S,
    duration: session.duration.S,
    season: session.season.S,
    startTime: session.startTime.S,
    updateTime: session.updateTime.S,
  };
};

const SessionOverview: FC = () => {
  const { getIdTokenClaims } = useAuth0();
  const [allSessions, setAllSessions] = useState<Session[]>([]);

  useEffect(() => {
    (async () => {
      try {
        // TODO: NOT RIGHT WAY TO ACCESS APIs!!
        const idtoken = await getIdTokenClaims();
        const token = idtoken.__raw;
        const response = await fetch(
          "https://orrnfk6l4g.execute-api.eu-west-3.amazonaws.com/prod/sessions?season=2021-2022",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseRawSessionList: AWSSession[] = await response.json();
        const responseSessionList = responseRawSessionList.map((session) =>
          parseSession(session)
        );
        setAllSessions(responseSessionList);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getIdTokenClaims]);

  const sessionsToList = useMemo(() => {
    const sessionLists: Session[][] = [[], [], []];
    allSessions.forEach((session, index) => {
      sessionLists[index % sessionLists.length].push(session);
    });
    return sessionLists;
  }, [allSessions]);

  return (
    <Row justify="center">
      <Col span={12}>
        <Row
          gutter={16}
          style={{ textAlign: "center", overflow: "auto", height: "85vh" }}
        >
          {sessionsToList.map((sessionList, i) => (
            <Col span={24 / sessionsToList.length} key={i}>
              {sessionList.map((session) => (
                <SessionCard session={session} key={session.creationTime} />
              ))}
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default SessionOverview;
