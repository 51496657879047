import { useAuth0 } from "@auth0/auth0-react";
import { Checkbox, Col, Row } from "antd";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import AthleteCard from "./AthleteCard";

type AWSAthlete = {
  activityStatus: { S: "active" | "inactive" };
  automaticAttendanceCode?: { S: string };
  creationTime: { S: string };
  dateOfBirth: { S: string };
  id: { S: string };
  firstName: { S: string };
  lastName: { S: string };
  lastSeen: { S: string };
  updateTime: { S: string };
};
export type Athlete = {
  activityStatus: "active" | "inactive";
  automaticAttendanceCode?: string;
  creationTime: string;
  dateOfBirth: string;
  id: string;
  firstName: string;
  lastName: string;
  lastSeen: string;
  updateTime: string;
};

export const parseAthlete = (athlete: AWSAthlete): Athlete => {
  return {
    activityStatus: athlete.activityStatus.S,
    automaticAttendanceCode: athlete.automaticAttendanceCode?.S,
    creationTime: athlete.creationTime.S,
    dateOfBirth: athlete.dateOfBirth.S,
    id: athlete.id.S,
    firstName: athlete.firstName.S,
    lastName: athlete.lastName.S,
    lastSeen: athlete.lastSeen.S,
    updateTime: athlete.lastSeen.S,
  };
};

const AthleteOverview: FC = () => {
  const { getIdTokenClaims } = useAuth0();
  const [allAthletes, setAllAthletes] = useState<Athlete[]>([]);

  const getAthleteList = useCallback(async () => {
    try {
      // TODO: NOT RIGHT WAY TO ACCESS APIs!!
      const idtoken = await getIdTokenClaims();
      const token = idtoken.__raw;
      // for aborting requests before finish:
      // https://dev.to/pallymore/clean-up-async-requests-in-useeffect-hooks-90h
      const response = await fetch(
        "https://orrnfk6l4g.execute-api.eu-west-3.amazonaws.com/prod/athletes?status=active",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseRawAthleteList: AWSAthlete[] = await response.json();
      const responseAthleteList = responseRawAthleteList.map((athlete) =>
        parseAthlete(athlete)
      );
      setAllAthletes(responseAthleteList);
    } catch (e) {
      console.error(e);
    }
  }, [getIdTokenClaims, setAllAthletes]);

  useEffect(() => {
    getAthleteList();
  }, [getAthleteList]);

  const athletesToList = useMemo(() => {
    const athleteLists: Athlete[][] = [[], []];
    allAthletes.forEach((athlete, index) => {
      athleteLists[index % athleteLists.length].push(athlete);
    });
    return athleteLists;
  }, [allAthletes]);

  return (
    <Row justify="center" gutter={16}>
      <Col span={3}>
        <div className="side-menu">
          <Link to="/atleten/nieuw">Nieuwe atleet</Link>
          <div>Filter:</div>
          <Checkbox>- Active</Checkbox>
          <Checkbox>- Inactive</Checkbox>
        </div>
      </Col>
      <Col span={12}>
        <Row
          gutter={16}
          style={{ textAlign: "center", overflow: "auto", height: "85vh" }}
        >
          {athletesToList.map((athleteList, i) => (
            <Col span={24 / athletesToList.length} key={i}>
              {athleteList.map((athlete) => (
                <AthleteCard athlete={athlete} key={athlete.creationTime} />
              ))}
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={3}></Col>
    </Row>
  );
};

export default AthleteOverview;
