import { useAuth0 } from "@auth0/auth0-react";
import { Button, Col, Row } from "antd";
import { FC } from "react";

const PublicLandingPage: FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Row justify="center">
      <Col>
        <Row>
          <Col>
            <h1>Welcome to Saturnal Attendances!</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              onClick={() => {
                loginWithRedirect({ returnTo: window.location.origin });
              }}
            >
              Log in
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PublicLandingPage;
