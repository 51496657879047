import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import "antd/dist/antd.css";
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      audience="https://saturnal.eu.auth0.com/api/v2/"
      clientId="3I33C44Kungc3WnnyaYPXfjGH6aOS2lo"
      domain="saturnal.eu.auth0.com"
      redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
