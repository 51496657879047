import { Col, Row } from "antd";
import React, { FC } from "react";
import { Athlete } from "./AthleteOverView";

type AthleteDetailProps = {
  athlete: Athlete;
};

const AthleteDetail: FC<AthleteDetailProps> = ({ athlete }) => {
  return (
    <Col>
      <Row>
        {athlete.firstName} {athlete.lastName}
      </Row>
      <Row>
        <Col>{athlete.dateOfBirth}</Col>
      </Row>
      <Row>
        <Col>{athlete.activityStatus}</Col>
      </Row>
    </Col>
  );
};

export default AthleteDetail;
